import { ICity } from "@/modules/user-location";

export function getSystemCountry() {
  switch (window.location.hostname) {
    case import.meta.env.VITE_FRONT_MD:
      return "md";
    case import.meta.env.VITE_FRONT_KZ:
      return "kz";
    default:
      return "ru";
  }
}

export function selectUserCity(city: ICity) {
  if (city.country !== getSystemCountry()) {
    switch (city.country) {
      case "kz":
        window.open(
          `https://${import.meta.env.VITE_FRONT_KZ}/?city=${city.id}`,
          "_self"
        );
        break;
      case "md":
        window.open(
          `https://${import.meta.env.VITE_FRONT_MD}/?city=${city.id}`,
          "_self"
        );
        break;
      default:
        window.open(
          `https://${import.meta.env.VITE_FRONT}/?city=${city.id}`,
          "_self"
        );
        break;
    }
    return;
  }

  localStorage.setItem("userCity", city.id.toString());
  window.location.reload();
}
