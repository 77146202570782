import { defineStore } from "pinia";

interface INotification {
  type: "info" | "warning" | "success" | "error";
  title?: string;
  text?: string;
}

export const useSystemStore = defineStore("system-store", {
  state: () => ({
    notification: null as null | INotification,
  }),

  actions: {
    showNotification({
      text = "",
      type = "error",
      title = "Что-то пошло не так...",
    }: INotification) {
      if (this.notification) {
        this.notification = null;
        setTimeout(() => {
          this.notification = {
            text,
            title,
            type,
          };
        }, 300);

        return;
      }

      this.notification = {
        text,
        title,
        type,
      };
    },

    hideNotification() {
      this.notification = null;
    },
  },
});
