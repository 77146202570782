import API from "@/package/config/axios";
import {
  ICityResponse,
  ICountriesResponse,
  ICountrySearchResponse,
} from "../types/backend-types";
import { cityParams } from "@/modules/user-location";

export const apiGetUserCityInfo = (city: cityParams) => {
  return API.get<ICityResponse>(`api/v2/info/city/${city}`);
};

export const apiGetCountries = () => {
  return API.get<ICountriesResponse>("api/v2/info/countries");
};

export const apiGetSearchCity = (params: { q: string; country: string }) => {
  return API.get<ICountrySearchResponse>("api/v2/search/city", {
    params: params,
  });
};
