<script setup lang="ts">
import { UiButton, UiModal } from "@packages/component-library";
import { onBeforeMount, ref } from "vue";
import APIPassport from "@/package/config/passport-api";

const emit = defineEmits(["close"]);
const buttonLoader = ref(false);

const confirmMarketingNotification = () => {
  if (!buttonLoader.value) {
    buttonLoader.value = true;

    try {
      APIPassport.post("api/v3/hub/person", { marketing_enabled: true }).then(
        () => {
          emit("close");
        }
      );
    } catch (e) {
      console.log(e);
    } finally {
      buttonLoader.value = false;
    }
  }
};

onBeforeMount(() => {
  APIPassport.post("api/v3/hub/person/marketing-shown");
});
</script>

<template>
  <UiModal hide-header size="s" @close="emit('close')">
    <template #default>
      <div class="marketing-notification">
        <img src="@/assets/images/marketing-info.svg" alt="" />
        <h5>Поделитесь данными</h5>
        <p>
          Разрешите отправку ваших контактных данных заведениям города, чтобы
          они могли отправлять вам персональные предложения
        </p>
      </div>
    </template>

    <template #actions>
      <div class="marketing-notification__actions">
        <UiButton
          label="Поделиться"
          size="m"
          :disabled="buttonLoader"
          :loading="buttonLoader"
          @click="confirmMarketingNotification"
        />
        <UiButton
          label="Не сейчас"
          color="secondary"
          size="m"
          @click="emit('close')"
        />
      </div>
    </template>
  </UiModal>
</template>

<style scoped lang="scss">
.marketing-notification {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  > h5 {
    @include h4();
    text-align: center;
    color: var(--color-semantic-content-normal-primary);
    margin: calc($base-space * 4) 0 0;
  }

  > p {
    margin: calc($base-space * 2) 0 0;
    @include body-m();
    color: var(--color-semantic-content-normal-primary);
    text-align: center;
    padding: 0 calc($base-space * 4);
  }

  &__actions {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: calc($base-space * 2);
    > button {
      width: 100%;
    }
  }
}
</style>
