import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "@/App.vue";
import router from "@/router";
import { createYmaps } from "vue-yandex-maps";
import "swiper/css";
import { register } from "swiper/element/bundle";
import i18n from "@/i18n";
import * as Sentry from "@sentry/vue";

import "@/scss/index.scss";
import "@packages/component-library/style.css";
import "@packages/color-tokens/style.css";
import "@packages/icon-library/style.css";

register();

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://e4f631365e292cc40288d085d5393d32@sentry.iilab.ru/55",
  integrations: [Sentry.browserTracingIntegration({ router })],
  tracesSampleRate: 1.0,
  enabled: import.meta.env.MODE !== "development",
});

app.use(createPinia());
app.use(router);
app.use(i18n);
app.use(
  createYmaps({
    apikey: import.meta.env.VITE_YANDEX_MAP_API_KEY,
  })
);

app.mount("#app");
