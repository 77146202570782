import API from "@/package/config/axios";
import {
  IRedirectResponse,
  ITotpResponse,
  IUserResponse,
} from "../types/backend-types";

export const apiGetUserInformation = () => {
  return API.get<IUserResponse>("api/v2/user/info");
};

export const apiGetUserTotp = () => {
  return API.get<ITotpResponse>("api/v2/user/totp");
};

export const apiUrlRedirect = (params: any) => {
  return API.get<IRedirectResponse>("/api/v2/info/redirect/url", {
    params: params,
  });
};
