import * as userLocationApi from "../api/user-location-api";

import { defineStore } from "pinia";
import { ICity, cityParams, ICountry } from "../types/frontend-types";
import { errorHandler } from "@/package/global-helpers/error-handler";

export const userLocationStore = defineStore("user-location-store", {
  state: () => ({
    countries: [] as Array<ICountry>,
    city: null as null | ICity,
    citiesSearchStatus: "loaded" as "loading" | "loaded",
    citiesSearchResults: [] as Array<ICity>,
  }),

  getters: {
    getCitiesByCountryId: (state) => {
      return (countryId: string) =>
        state.countries.find((country) => country.id === countryId)?.cities;
    },
    getCityCoordinates: (state) => [
      state.city?.latitude,
      state.city?.longitude,
    ],
  },

  actions: {
    async loadUserCityInformation(city: cityParams) {
      try {
        const resp = await userLocationApi.apiGetUserCityInfo(city);
        this.city = resp.data.data.city;
      } catch (error: Error | unknown) {
        errorHandler(error, false);
        throw error;
      }
    },

    async loadCountries() {
      try {
        const resp = await userLocationApi.apiGetCountries();
        this.countries = resp.data.data.countries;
      } catch (error: Error | unknown) {
        errorHandler(error, false);
        throw error;
      }
    },

    async searchCities(params: { country: string; q: string }) {
      this.citiesSearchStatus = "loading";
      this.citiesSearchResults = [];
      try {
        const resp = await userLocationApi.apiGetSearchCity(params);
        this.citiesSearchResults = resp.data.data;
      } catch (error: Error | unknown) {
        errorHandler(error, false);
        throw error;
      } finally {
        this.citiesSearchStatus = "loaded";
      }
    },
  },
});
