import * as cartAPI from "../api/cart-api";
import { defineStore } from "pinia";
import { ICart } from "../types/frontend-types";
import { errorHandler } from "@/package/global-helpers/error-handler";

export const useCartStore = defineStore("cart-store", {
  state: () => ({
    cartsLoadingStatus: "loading" as "loading" | "loaded",
    carts: [] as Array<ICart>,
    cart: null as null | ICart,
  }),

  getters: {
    getCartsLength: (state) => {
      return state.carts.length;
    },
    getCartProductsLength: (state) => {
      return state.carts.map((cart) => cart.products).flat().length;
    },
  },

  actions: {
    async loadCarts() {
      this.cartsLoadingStatus = "loading";
      try {
        const resp = await cartAPI.apiGetCarts();
        this.carts = resp.data.data.place_carts;
      } catch (error: Error | unknown) {
        errorHandler(error, true);
        throw error;
      } finally {
        this.cartsLoadingStatus = "loaded";
      }
    },

    async updateCartProduct(params: { product_id: number; count: number }) {
      try {
        const resp = await cartAPI.apiUpdateProductInCart(params);
        this.carts = resp.data.data.place_carts;
      } catch (error: Error | unknown) {
        errorHandler(error, true);
        throw error;
      }
    },

    async clearCart(cartId: number) {
      try {
        await cartAPI.apiClearCart(cartId).then(() => {
          this.loadCarts();
        });
      } catch (error: Error | unknown) {
        errorHandler(error, true);
        throw error;
      }
    },
  },
});
