import axios, { AxiosRequestConfig } from "axios";
import { useCookies } from "@vueuse/integrations/useCookies";

const cookies = useCookies();

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

const PASSPORT_BASE_URL = import.meta.env.VITE_PASSPORT_BACKEND;

const APIPassport = axios.create({
  baseURL: PASSPORT_BASE_URL,
  withCredentials: true,
});

APIPassport.interceptors.request.use(
  (config) => {
    if (cookies.get("ksUserToken")) {
      config.headers["Authorization"] = `Bearer ${cookies.get("ksUserToken")}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const refreshAndRetryQueue: RetryQueueItem[] = [];
let isRefreshing = false;

const clearUserToken = () => {
  cookies.remove("ksUserToken", {
    path: "/",
    domain: import.meta.env.VITE_HOST,
  });
  cookies.remove("ksUserRefresh", {
    path: "/",
    domain: import.meta.env.VITE_HOST,
  });
  window.location.reload();
};

APIPassport.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest: AxiosRequestConfig = error.config;

    if (error.response && error.response.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const resp = await APIPassport.post("api/v2/oauth/token/refresh", {
            refresh_token: cookies.get("ksUserRefresh"),
          });

          cookies.set("ksUserToken", resp.data.data.access_token, {
            expires: new Date(
              new Date().setFullYear(new Date().getFullYear() + 1)
            ),
            path: "/",
            domain: import.meta.env.VITE_HOST,
            sameSite: "lax",
          });
          cookies.set("ksUserRefresh", resp.data.data.refresh_token, {
            expires: new Date(
              new Date().setFullYear(new Date().getFullYear() + 1)
            ),
            path: "/",
            domain: import.meta.env.VITE_HOST,
            sameSite: "lax",
          });

          error.config.headers["Authorization"] = cookies.get("ksUserToken");

          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            APIPassport.request(config)
              .then((response) => resolve(response))
              .catch((err) => {
                if (err.response.status === 401) {
                  clearUserToken();
                }
                reject(err);
              });
          });

          refreshAndRetryQueue.length = 0;

          return APIPassport(originalRequest);
        } catch (refreshError) {
          if (refreshError.response.status === 403) {
            clearUserToken();
          }
          throw refreshError;
        } finally {
          isRefreshing = false;
        }
      }

      return new Promise<void>((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }

    return Promise.reject(error);
  }
);

export default APIPassport;
