import axios, { AxiosRequestConfig } from "axios";
import { getSystemCountry } from "@/package/global-helpers/system-country";
import { useCookies } from "@vueuse/integrations/useCookies";

const cookies = useCookies();

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

export let BASE_URL = "";

switch (getSystemCountry()) {
  case "md":
    BASE_URL = import.meta.env.VITE_BACKEND_MD;
    break;
  case "kz":
    BASE_URL = import.meta.env.VITE_BACKEND_KZ;
    break;
  default:
    BASE_URL = import.meta.env.VITE_BACKEND;
}

const API = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

API.interceptors.request.use(
  (config) => {
    if (cookies.get("ksUserToken")) {
      config.headers["Authorization"] = cookies.get("ksUserToken");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const refreshAndRetryQueue: RetryQueueItem[] = [];
let isRefreshing = false;

const clearUserToken = () => {
  cookies.remove("ksUserToken", {
    path: "/",
    domain: import.meta.env.VITE_HOST,
  });
  cookies.remove("ksUserRefresh", {
    path: "/",
    domain: import.meta.env.VITE_HOST,
  });
  window.location.reload();
};

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest: AxiosRequestConfig = error.config;

    if (error.response && error.response.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const resp = await API.post("api/v2/oauth/token/refresh", {
            refresh_token: cookies.get("ksUserRefresh"),
          });

          cookies.set("ksUserToken", resp.data.data.access_token, {
            expires: new Date(
              new Date().setFullYear(new Date().getFullYear() + 1)
            ),
            path: "/",
            domain: import.meta.env.VITE_HOST,
            sameSite: "lax",
          });
          cookies.set("ksUserRefresh", resp.data.data.refresh_token, {
            expires: new Date(
              new Date().setFullYear(new Date().getFullYear() + 1)
            ),
            path: "/",
            domain: import.meta.env.VITE_HOST,
            sameSite: "lax",
          });

          error.config.headers["Authorization"] = cookies.get("ksUserToken");

          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            API.request(config)
              .then((response) => resolve(response))
              .catch((err) => {
                if (err.response.status === 401) {
                  clearUserToken();
                }
                reject(err);
              });
          });

          refreshAndRetryQueue.length = 0;

          return API(originalRequest);
        } catch (refreshError) {
          if (refreshError.response.status === 403) {
            clearUserToken();
          }
          throw refreshError;
        } finally {
          isRefreshing = false;
        }
      }

      return new Promise<void>((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }

    return Promise.reject(error);
  }
);

export default API;
